import React, { useState } from "react";
import ReactDOM from "react-dom";
import { IconWithText } from "../components/system/icons/Icon";
import { Bars3Icon } from "@heroicons/react/20/solid";
import { Item } from "../components/nav-bar/Item";

function NavBar() {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const healthProfTabClasses =
    "py-4 md:py-6 px-4 text-sm 3xl:px-6 3xl:text-base text-white hover:text-white ml-0 lg:ml-auto bg-steel-blue-500";
  const healthProfTabActiveClasses = `text-decoration: underline`;
  const healthProfIsActive = window.location.href.includes(
    "/health_professional",
  );
  const healthProfClasses =
    healthProfIsActive === true
      ? `${healthProfTabClasses} ${healthProfTabActiveClasses}`
      : healthProfTabClasses;

  return (
    <div className={`bg-steel-blue-600 text-center font-bold lg:text-left`}>
      <div
        className={`block lg:hidden flex justify-center text-white py-4`}
        onClick={toggleMenu}
      >
        <a
          role="button"
          aria-expanded={showMenu}
          href="#"
          onClick={toggleMenu}
          className="hover:text-white"
        >
          <IconWithText icon={<Bars3Icon />}>Menu</IconWithText>
        </a>
      </div>
      <nav
        className={`${
          showMenu ? "" : `hidden lg:flex`
        } flex flex-col lg:flex-row w-full xl:w-10/12 mx-auto`}
      >
        <Item to="/about">About KeepSight</Item>
        <Item to="/eye_health_diabetes">Eye health and diabetes</Item>
        <Item to="/first_nations">First Nations eye health</Item>
        <Item to="/patient_enrolments/new?event=sign_up_keepsight&source=home">
          Sign up for KeepSight
        </Item>
        <Item to="/provider_search">Find an optometrist</Item>
        <Item to="/articles">Blog</Item>
        <a href="/health_professional" className={`${healthProfClasses}`}>
          For health professionals
        </a>
      </nav>
    </div>
  );
}

document.addEventListener("DOMContentLoaded", () => {
  const navBarRoot = document.querySelector("#nav-bar");
  if (navBarRoot) {
    ReactDOM.render(
      <NavBar />,
      navBarRoot.appendChild(document.createElement("div")),
    );
  }
});
